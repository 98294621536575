@tailwind base;
@tailwind components;
@tailwind utilities;

input:checked + div svg {
  @apply block;
}

body {
  margin: 0;
}
